@import '~antd/dist/antd.css';

.ant-segmented {
  background-color: white;
}
.ant-segmented-item {
  color: #b0b0b0;
}
.ant-segmented-item-selected {
  background-color: #c4c4c4;
  color: white;
}

.ant-select {
  background-color: #f3f3f3;
  /* padding: 0px 0.85rem 0px 0.85rem; */
}
.ant-select-selector {
  background-color: #f3f3f3 !important;
  font-size: 20px;
  font-weight: 500;
}
.ant-select-selection-item {
  font-size: 20px;
  font-weight: 500;
}

.ant-select-arrow {
  cursor: pointer;
  user-select: none;
  font-size: 14px;
  font-weight: 500;
  color: black;
  height: 35%;
}
